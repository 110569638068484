import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/Headers/PageHeader.js";
import ArticleSwiper from "components/ArticleSwiper.js";

import "assets/scss/about.scss";

const items = [
    {src: "https://cdn.suns.art/photo/swipe/about/1.jpeg?x-oss-process=style/1080",},
]

const chapter1Items = [
    "https://cdn.suns.art/photo/swipe/about/01zcnx/1.jpg?x-oss-process=style/540v_43",
    "https://cdn.suns.art/photo/swipe/about/01zcnx/2.jpg?x-oss-process=style/540v_43",
    "https://cdn.suns.art/photo/swipe/about/01zcnx/3.jpg?x-oss-process=style/540v_43"
]
const chapter2Items = [
    "https://cdn.suns.art/photo/swipe/about/02zgsf/1.jpg?x-oss-process=style/540v_43",
    "https://cdn.suns.art/photo/swipe/about/02zgsf/2.jpg?x-oss-process=style/540v_43",
    "https://cdn.suns.art/photo/swipe/about/02zgsf/3.jpg?x-oss-process=style/540v_43"
]
const chapter3Items = [
    "https://cdn.suns.art/photo/swipe/about/03xywd/1.jpg?x-oss-process=style/540v_43",
    "https://cdn.suns.art/photo/swipe/about/03xywd/2.jpg?x-oss-process=style/540v_43",
    "https://cdn.suns.art/photo/swipe/about/03xywd/3.jpg?x-oss-process=style/540v_43"
]
const chapter4Items = [
    "https://cdn.suns.art/photo/swipe/about/04yskj/1.jpg?x-oss-process=style/540v_43",
]

const title = "About<br/>SunS Living Gallery"

function LandingPage() {
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.body.classList.add("menu-on-left");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <IndexNavbar/>
            <div className="wrapper">
                <PageHeader items={items} title={title}/>
                <div className="section section-about-us">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">开启家居画廊新定义</h2>
                                <h4 className="description">
                                    SunS Living Gallery 家居画廊<br/>
                                    创立于2021年<br/>
                                    是一家具有北京城市特色<br/>
                                    小众宝藏家居画廊集合店
                                </h4>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title article-section-title">Living + Gallery</h2>
                                <p>打破、模糊了“生活（家）”与“艺术（藏品）”之间的空间边界，营造融合、交互的空间关系，使得“生活艺术化”与“艺术生活化”。</p>
                            </Col>
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/2.jpg?x-oss-process=style/540"
                                    className="article-image"
                                    alt={"Living"}/>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/3.png?x-oss-process=style/540"
                                    className="article-image" alt={"Lifestyle"}/>
                            </Col>
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title article-section-title">Lifestyle +</h2>
                                <h2 className="title article-section-title">Artistic Space</h2>
                                <p>持续的将人们带到家的空间中与艺术展开对话，以人为核心，以家为空间载体，不断向“内”探寻，创造美学与性能融合的居家艺术场景。</p>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title article-section-title">Personality &</h2>
                                <h2 className="title article-section-title">Unique</h2>
                                <p>为艺术家、设计师们提供与人们深度交流的机会，作品本身真实的、充分的、戏剧的表达个人对生活的理解与态度。</p>
                            </Col>
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/4.png?x-oss-process=style/540"
                                    className="article-image" alt={"Personality"}/>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-bottom">
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/5.png?x-oss-process=style/540"
                                    className="article-image" alt={"打造"}/>
                            </Col>
                            <Col md={{size: 4, offset: 1}}>
                                <p>打造别具一格与家庭空间密不可分的新型现代家居画廊，传播艺术之美：当人们随意走至家中的某个拐角，一个瞬间邂逅艺术。</p>
                            </Col>
                        </Row>

                        <div className="separator"></div>
                        <div className="separator"></div>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">为您提供专属服务</h2>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title no-margin no-padding">SunS DESIGN</h2>
                                <h2 className="title no-padding">家居</h2>
                                <p>找到与你有呼应、相互吸引的家居选品，让家的“每平米都可以更艺术”。为兼并美学与功能性，每一件艺术单品背后，是设计师，艺术家与匠人们不断探索的过程。生活的无限可能性，皆在此间。
                                    <br/>
                                    <br/>
                                    合作品类<br/>
                                    家具、灯具、餐具、香氛、装饰
                                    <br/>
                                    <br/>
                                    合作品牌<br/>
                                    Driade，Desede，Cassina，Classicon，Glas，FermLiving，Fiam，Miniforms，Mogg，Bylessen，mariani，FontanaArte，Ingo
                                    maurer，Marset，Ginori ...</p>
                            </Col>
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/6.png?x-oss-process=style/540v"
                                    className="article-image" alt={"DESIGN"}/>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/7.png?x-oss-process=style/540v"
                                    className="article-image" alt={"ART"}/>
                            </Col>
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title no-margin no-padding">SunS ART</h2>
                                <h2 className="title no-padding">艺术品</h2>
                                <p>全球甄选艺术品，谁说家不可以是“艺术藏馆”呢。艺术与生活的结合，并非简单的两两叠加，而是自然的，间而不断的发生，让生活与艺术自然的、舒适的对话。</p>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title no-margin no-padding">SunS HOME</h2>
                                <h2 className="title no-padding">家装</h2>
                                <p>全屋经典设计案例，为您的品质生活全方位提案；设计团队为您打造专属人居美学方案，为品质生活全方位提案。</p>
                            </Col>
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/8.png?x-oss-process=style/540v"
                                    className="article-image" alt={"HOME"}/>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 1}}>
                                <img
                                    src="https://cdn.suns.art/photo/article/about/9.png?x-oss-process=style/540v"
                                    className="article-image" alt={"EVENTS"}/>
                            </Col>
                            <Col md={{size: 4, offset: 1}}>
                                <h2 className="title no-margin no-padding">SunS EVENTS</h2>
                                <h2 className="title no-padding">活动</h2>
                                <p>自由灵活组合空间组合，为各种策展、沙龙、活动提供艺术家居空间及场域支持。</p>
                            </Col>
                        </Row>

                        <div className="separator"></div>
                        <div className="separator"></div>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">胡同里的家居画廊</h2>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 4, offset: 1}}>
                                <h2>早春南厢</h2>
                                <h4>天光墨瓦，灼映华楹</h4>
                                <p>若被斑驳青砖墙中炫彩玻璃橱窗所吸引，请推开古色古香的清末木门，随着吱呀作响的修缮旧木穿越时空，一睹天光庭院内的精彩。</p>
                            </Col>
                            <Col md={{size: 5, offset: 1}}>
                                <ArticleSwiper items={chapter1Items}/>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 1}}>
                                <ArticleSwiper items={chapter2Items}/>
                            </Col>
                            <Col md={{size: 4, offset: 1}}>
                                <h2>仲谷书房</h2>
                                <h4>别居洞天，琉璃之心</h4>
                                <p>随烟紫色踏步行至二层，可见空间的通透、气流的循环与光影的映射。“仲”为二，“谷”则寓意着对照厢房打造的坡型屋顶。</p>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 4, offset: 1}}>
                                <h2>小院屋顶</h2>
                                <h4>极目畅望，豁然开朗</h4>
                                <p>玻璃房几无阻隔，景观一览无余，是浸润着度假阳光的露台展厅。极目远眺，胡同里的带有烟火气的青瓦屋顶如山峦重叠，远处的鳞次栉比的国贸大厦则闪烁着国际大都市的繁华与急促。身处旧城之巅，暂忘俗世烦恼，俯身拂去往昔尘埃，对酒邀月思量明日。</p>
                            </Col>
                            <Col md={{size: 5, offset: 1}}>
                                <ArticleSwiper items={chapter3Items}/>
                            </Col>
                        </Row>
                        <div className="separator"></div>

                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 1}}>
                                <ArticleSwiper items={chapter4Items}/>
                            </Col>
                            <Col md={{size: 4, offset: 1}}>
                                <h2>艺术空间</h2>
                                <h4>融合多元，邂逅艺术</h4>
                                <p>120㎡ 开阔一体化地下艺术空间<br/>
                                    自由、灵活组合出多元化的策展、活动内容</p>
                            </Col>
                        </Row>

                        <div className="separator"></div>
                        <div className="separator"></div>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">空间平面图</h2>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <div className="separator"></div>

                        <Row>
                            <Col md={12}>
                                <img src="https://cdn.suns.art/img/about/map.png" alt={"map"}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <DefaultFooter/>
            </div>
        </>
    );
}

export default LandingPage;

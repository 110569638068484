import React from "react";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

import IndexSwiper from "./index/IndexSwiper";
import IndexTitle from "./index/IndexTitle";

function IndexPage() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.body.classList.add("menu-on-left");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <IndexNavbar/>
            <div className="wrapper">
                <div className="main">
                    <IndexSwiper/>
                </div>
                <DarkFooter/>
            </div>
        </>
    );
}

export default IndexPage;

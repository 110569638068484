import React from "react";

function IndexTitle() {
    return (
        <div id="title-wrap">
            <div className="l1">Living+</div>
            <div className="l2">Gallery</div>
            <div className="l3">开启家居画廊新定义</div>
        </div>
    );
}

export default IndexTitle;
